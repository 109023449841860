import { toCamel, toSnake } from 'convert-keys'
import config from '../../config'
import { Answer } from '../../models/answer'
import { Episode } from '../../models/episode'
import { Series } from '../../models/series'
import { PostParticipation } from '../tehtava-api/tehtava-api'
import {
  RawEpisode,
  RawLeaderboard,
  RawSeries,
  formatEpisode,
  formatSeries,
  handleErrors,
  tehtavaFetch
} from '../tehtava-api/utils'
import { EpisodeScore, PlayerScore } from '../../models/playerScore'
import { Participation } from '../../models/participation'

const POST_HEADERS = { 'Content-Type': 'application/json; charset=utf-8' }
const hengaillaanApiUrl = `${config.vuorovaikutusApiUrl}/hengaillaan`

const createUrl = (path: string): string => {
  return `${hengaillaanApiUrl}/${path}`
}

export const fetchTvEpisode = async (uuid: string): Promise<Episode> => {
  const endpointUrl = createUrl(`episode/${uuid}`)
  const rawEpisodes = await tehtavaFetch<RawEpisode>(endpointUrl, {
    method: 'GET',
    credentials: 'include'
  })

  return formatEpisode(rawEpisodes[0]) as Episode
}

export const fetchSeries = async (uuid: string): Promise<Series> => {
  const endpointUrl = createUrl(`series/${uuid}`)
  const rawSeries = await tehtavaFetch<RawSeries>(endpointUrl, {
    method: 'GET',
    credentials: 'include'
  })
  return formatSeries(rawSeries[0])
}

export const postAnswer = async (answer: Answer): Promise<Response> => {
  const url = createUrl('answers')
  const body = JSON.stringify(toSnake(answer))
  return handleErrors(
    await fetch(url, {
      method: 'POST',
      headers: POST_HEADERS,
      credentials: 'include',
      body
    })
  )
}

export const fetchAnswers = (uuid: string): Promise<Answer[]> => {
  const url = createUrl(`answers/${uuid}`)
  return tehtavaFetch<Answer>(url, {
    method: 'GET',
    credentials: 'include'
  })
}

export const fetchTvEpisodeScores = (uuid: string): Promise<EpisodeScore[]> => {
  const url = createUrl(`scores/${uuid}`)
  return tehtavaFetch(url, {
    method: 'GET',
    credentials: 'include'
  })
}

export const postParticipation: PostParticipation = async participation => {
  const url = createUrl('participations')
  const body = JSON.stringify(toSnake([participation]))
  const response = handleErrors(
    await fetch(url, {
      method: 'POST',
      headers: POST_HEADERS,
      credentials: 'include',
      body
    })
  )
  const responseBody: { participate: Participation } = toCamel(await response.json())
  return responseBody.participate
}

export const fetchParticipation = async (seriesUuid: string): Promise<Participation> => {
  const url = createUrl(`participation/${seriesUuid}`)
  const response = handleErrors(
    await fetch(url, {
      method: 'GET',
      credentials: 'include'
    })
  )
  const responseBody: Participation = toCamel(await response.json())
  return responseBody
}

// Fetch leaderboard for the whole competition. All questions belong to a single serie.
export const fetchLeaderboard = async (uuid: string): Promise<PlayerScore[]> => {
  const url = createUrl(`leaderboard/${uuid}`)

  const response = handleErrors(
    await fetch(url, {
      method: 'GET',
      credentials: 'include'
    })
  )

  const responseBody: RawLeaderboard = toCamel(await response.json())

  return responseBody.leaderboard
}

export const vuorovaikutusLogin = async (): Promise<Response> => {
  const url = `${config.vuorovaikutusApiUrl}/user/login`
  return handleErrors(
    await fetch(url, {
      method: 'GET',
      credentials: 'include'
    })
  )
}
