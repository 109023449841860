import React, { FC } from 'react'
import config from '../../config'
import strings from '../../utils/strings'
import Button from '../YdsButton/YdsButton'
import Modal from '../Modal/Modal'
import * as styles from './infomodal.module.css'
import { scrollToStart } from '../../utils/scroll'

type InfoModalProps = {
  onClose: () => void
}

const LOTTERY_LINK = 'https://yle.fi/aihe/hengaillaan'

const InfoModal: FC<InfoModalProps> = ({ onClose }) => {
  const onClickFeedback = React.useCallback(() => {
    window.open(config.feedbackLink)
  }, [])

  const onClickLotteryLink = React.useCallback(() => {
    window.open(LOTTERY_LINK)
  }, [])

  React.useEffect(() => {
    scrollToStart()
  }, [])

  return (
    <Modal onClose={onClose}>
      <div className={styles.infoModalContent}>
        <h2 className={styles.infoModalHeader}>{strings.welcome}</h2>
        <p>{strings.giveFeedbackText}</p>
        <p></p>
        <Button onClick={onClickFeedback} text={strings.giveFeedback} />
        <h2 className={styles.infoModalHeader}>{strings.instructions}</h2>
        <p>{strings.instructionText1}</p>
        <p>{strings.instructionText2}</p>
        <p>{strings.instructionText3}</p>
        <p>{strings.instructionText4}</p>
        <p>{strings.instructionText5}</p>
        <p>{strings.instructionText6}</p>
        <p>{strings.instructionText7}</p>
        <p>{strings.instructionText7b}</p>
        <p>{strings.instructionText8}</p>
        <p>
          {strings.instructionText9}
          <a onClick={onClickLotteryLink} href="#">
            {LOTTERY_LINK}
          </a>
          {'.'}
        </p>
        <p></p>
        <Button
          className={styles.marginTop}
          variant="secondary"
          onClick={onClose}
          text={strings.close}
        />
      </div>
    </Modal>
  )
}

export default InfoModal
