import React, { useCallback, useEffect } from 'react'
import * as api from '../api/api-wrapper'
import { PlayerScore } from '../models/playerScore'

export const useLeaderboard = (seriesId: string): [PlayerScore[], () => void, boolean] => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [leaderboard, setLeaderboard] = React.useState<PlayerScore[]>([])
  const fetchPlayerScores = useCallback(async () => {
    setIsLoading(true)
    try {
      const playerScores = await api.fetchLeaderboard(seriesId)

      // Sort the leaderboard by score
      playerScores.sort(
        (firstItem: PlayerScore, secondItem: PlayerScore) =>
          Number(firstItem.orderNumber) - Number(secondItem.orderNumber)
      )

      setLeaderboard(playerScores)
    } catch (e) {
      // TODO: handle errors
      if (window.getEnv() === 'dev') {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    } finally {
      setIsLoading(false)
    }
  }, [seriesId])

  useEffect(() => {
    setLeaderboard([])
    fetchPlayerScores()
  }, [seriesId, fetchPlayerScores])

  return [leaderboard, fetchPlayerScores, isLoading]
}
