
.container {
  width: 100%;
  min-width: 300px;
  max-width: 540px;
  position: relative;
}

.imageContainer {
  width: 75%;
  margin: 0 auto;
}

.image {
  padding: 0 5% 5% 5%;
  object-fit: contain;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.textImageContainer {
  position: absolute;
  top: 55%;
  z-index: 2;
}

.textImage {
  object-fit: contain;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
