import React, { FC } from 'react'
import { YdsThemeProvider } from '@yleisradio/yds-components-react'
import subscribeToYleTunnus from './state/yleTunnus/yleTunnus'
import YleTunnusContext, { defaultState as yleTunnusDefaultState } from './state/yleTunnus/context'
import Router from './pages/Router'
import DevTools from './components/DevTools/DevBar'
import config from './config'
import LoginPlease from './components/LoginPlease/LoginPlease'
import * as styles from './app.module.css'
import WebviewGuard from './components/WebviewGuard/WebviewGuard'

const App: FC = () => {
  const [yleTunnusState, setYleTunnusState] = React.useState(yleTunnusDefaultState)

  React.useEffect(() => {
    subscribeToYleTunnus(setYleTunnusState)
  }, [])

  return (
    <YleTunnusContext.Provider value={yleTunnusState}>
      <YdsThemeProvider theme="dark">
        <div className={styles.app}>
          {config.showDevTools && <DevTools />}
          <WebviewGuard>
            {yleTunnusState.loggedIn === true && <Router />}
            {yleTunnusState.loggedIn === false && <LoginPlease />}
          </WebviewGuard>
        </div>
      </YdsThemeProvider>
    </YleTunnusContext.Provider>
  )
}

export default App
