
.container {
  display: flex;
  flex-direction: row;
  box-shadow: var(--shadow-sm);
  overflow: hidden;
  border-radius: var(--border-radius-sm);
}

.left {
  background-color: var(--color-black-3);
  max-width: 80px;
}

.right {
  background-color: var(--color-black-4);
  min-width: 160px;
  max-width: 200px;
}

.titleContainer {
  background-image: linear-gradient(to bottom right, var(--color-beige-3), var(--color-beige-2), var(--color-beige-1));
  padding: 6px 12px;
  margin-bottom: 4px;
}

.title {
  color: black;
  font-size: 16px;
  padding: 0;
  margin: 0;
}

.divider {
  width: 100%;
  height: 1px;
  background: white;
}

.textContainer {
  padding: 6px;
}
