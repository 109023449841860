import React from 'react'
import * as api from '../api/api-wrapper'
import { Answer } from '../models/answer'
import { Option, Question } from '../models/question'

export type AnswerData = {
  answers: Answer[]
  isLoading: boolean
  onPostAnswer: (answer: UnsavedAnswer) => Promise<void>
}

export type UnsavedAnswer = {
  question: Question
  option: Option
}

const createTehtavaAnswer = (answer: UnsavedAnswer) => ({
  questionUuid: answer.question.uuid,
  answerData: { [answer.option.id]: answer.option.text },
  score: answer.option.correct ? Number(answer.question.settings.points) : 0
})

export const useAnswers = (episodeId: string): AnswerData => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [answers, setAnswers] = React.useState<Answer[]>([])

  React.useEffect(() => {
    const fetchAnswers = async () => {
      try {
        const answers = await api.fetchAnswers(episodeId)
        setAnswers(answers)
      } catch (e) {
        // TODO: handle errors
        if (window.getEnv() === 'dev') {
          // eslint-disable-next-line no-console
          console.log(e)
        }
      } finally {
        setIsLoading(false)
      }
    }

    setIsLoading(true)
    setAnswers([])
    fetchAnswers()
  }, [episodeId])

  const onPostAnswer = React.useCallback(async (answer: UnsavedAnswer) => {
    const savedAnswer = createTehtavaAnswer(answer)
    await api.postAnswer(savedAnswer)
    setAnswers(answers => answers.concat([savedAnswer]))
  }, [])

  return { answers, isLoading, onPostAnswer }
}
