import { type Answer } from '../models/answer'
import { Question } from '../models/question'

const getAnsweredOptionId = (answer: Answer | undefined) => {
  return answer ? Object.keys(answer.answerData)[0] : null
}

export const isCorrectlyAnswered = ({
  answer,
  question
}: {
  answer: Answer | undefined
  question: Question | undefined
}) => {
  const answeredOptionId = getAnsweredOptionId(answer)
  const correctOption = question?.options.find(o => o.correct)
  return answeredOptionId === correctOption?.id.toString()
}

export const getChosenOption = ({
  answer,
  question
}: {
  answer: Answer | undefined
  question: Question
}) => {
  const answeredOptionId = getAnsweredOptionId(answer)
  return question.options.find(o => o.id.toString() === answeredOptionId)
}

export const getQuestionForAnswer = ({
  answer,
  questions
}: {
  answer: Answer | undefined
  questions: Question[] | undefined
}) => {
  return questions?.find(q => q.uuid === answer?.questionUuid)
}
