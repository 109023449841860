import React from 'react'
import { createRoot } from 'react-dom/client'
import isBetween from 'dayjs/plugin/isBetween'
import dayjs from 'dayjs'
import App from './App'

dayjs.extend(isBetween)
const app = document.getElementById('app')
const root = createRoot(app)
root.render(<App />)
