import React, { FC, ReactNode, useCallback } from 'react'
import Button from '../../components/Button/Button'
import { ChevronLeft } from '@yleisradio/yds-icons-react'
import { useNavigate } from 'react-router-dom'
import strings from '../../utils/strings'

type BackButtonProps = {
  className?: string
  children?: ReactNode
  toRoute?: string
  noIcon?: boolean
}

const BackButton: FC<BackButtonProps> = ({ children, className, toRoute, noIcon }) => {
  const navigate = useNavigate()

  const onGoBack = useCallback(() => {
    toRoute ? navigate(toRoute) : navigate(-1)
  }, [navigate, toRoute])

  return (
    <Button
      className={className}
      onClick={onGoBack}
      iconBefore={noIcon ? null : <ChevronLeft />}
      variant="text"
    >
      {children ? children : strings.goBack}
    </Button>
  )
}

export default BackButton
