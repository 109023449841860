import React, { FC } from 'react'
import * as styles from './liveIndicator.module.css'
import liveGreen from './liveGreen.svg'
import liveWhite from './liveWhite.svg'
import strings from '../../utils/strings'

type LiveIndicatorProps = {
  isLive?: boolean
  episodeName: string
}

const LiveIndicator: FC<LiveIndicatorProps> = ({ episodeName, isLive }) => {
  const [episode, number] = episodeName.split(' ')
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img className={styles.liveImage} src={isLive ? liveGreen : liveWhite} />
        <div className={styles.episode}>
          <p className={styles.episodeName}>{episode}</p>
          <p className={styles.episodeNumber}>{number}</p>
        </div>
      </div>
      <div className={`${styles.liveText} ${isLive ? styles.isLive : ''}`}>{strings.live}</div>
    </div>
  )
}

export default LiveIndicator
