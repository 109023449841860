import React, { FC, ReactNode } from 'react'
import { YdsThemeProvider } from '@yleisradio/yds-components-react'
import * as styles from './modal.module.css'
import clock from './clock.svg'

type ModalProps = {
  onClose: () => void
  children: ReactNode
}

const Modal: FC<ModalProps> = ({ children }) => (
  <YdsThemeProvider theme="light">
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
    </div>
  </YdsThemeProvider>
)

type TrainInfoModalProps = { button: ReactNode; header: ReactNode; content: ReactNode }

export const TrainInfoModal: FC<TrainInfoModalProps> = ({ header, content, button }) => (
  <div className={styles.trainInfoContainer}>
    <div className={styles.trainInfoContent}>
      <div className={styles.trainInfoPanels}>
        <div className={styles.trainInfoLeftPanel}>
          <img className={styles.trainInfoClock} src={clock} />
        </div>
        <div className={styles.trainInfoRightPanel}>
          {header}
          <div className={styles.trainInfoRightPanelDivider} />
          <div className={styles.trainInfoText}>{content}</div>
        </div>
      </div>
    </div>
    <div className={styles.trainInfoButtonContainer}>{button}</div>
  </div>
)

export default Modal
