import React, { FC, ReactNode } from 'react'
import * as styles from './messageContainer.module.css'

type MessageContainerProps = {
  left: ReactNode
  title: string
  text?: string
}

const MessageContainer: FC<MessageContainerProps> = ({ left, title, text }) => {
  return (
    <div className={styles.container}>
      <div className={styles.left}>{left}</div>
      <div className={styles.right}>
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>{title}</h2>
        </div>
        <div className={styles.divider} />
        <div className={styles.textContainer}>{text}</div>
      </div>
    </div>
  )
}

export default MessageContainer
