
.sharp {
  background-color: var(--color-black-1);
  width: 8px;
  height: 22px;
}

.rounded {
  border-radius: var(--border-radius-sm);
  background-color: var(--color-black-2);
}

.roundedBottom {
  border-radius: 0 0 var(--border-radius-sm) var(--border-radius-sm);
  background-color: var(--color-black-2);
}

.shadow {
  box-shadow: var(--shadow-md);
}
