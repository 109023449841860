import React, { FC, ReactNode } from 'react'
import * as styles from './square.module.css'

type Shape = 'sharp' | 'rounded' | 'roundedBottom'

type SquareProps = {
  shape: Shape
  children?: ReactNode
  hasShadow?: boolean
}

const getClassName = (shape: Shape) => {
  switch (shape) {
    case 'rounded':
      return styles.rounded
    case 'sharp':
      return styles.sharp
    case 'roundedBottom':
      return styles.roundedBottom
  }
}

const Square: FC<SquareProps> = ({ children, shape = 'rounded', hasShadow }) => {
  return (
    <div className={`${getClassName(shape)} ${hasShadow ? styles.shadow : ''}`}>{children}</div>
  )
}

export default Square
