.loginPlease {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 32px;
  text-align: center;
  font-size: 24px;
}

.arrow {
  flex: 1 0 0;
  background: no-repeat top right/50% 95% url(./arrow.png);
}

.text {
  flex: 0 0 auto;

}

.blank {
  flex: 1 0 0;
}
