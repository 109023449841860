import React, { FC } from 'react'
import * as styles from './waitforresultspage.module.css'
import ROUTES from '../../utils/routes'
import { useNavigate } from 'react-router-dom'
import { EpisodeData } from '../../hooks/useEpisode'
import { AnswerData } from '../../hooks/useAnswers'
import strings from '../../utils/strings'
import { getQuestions } from '../../utils/episodes'
import { PageLoadingIndicator } from '../../components/LoadingIndicator/LoadingIndicator'
import Lobby from '../../components/Lobby/Lobby'
import { useIsTimePassed } from '../../hooks/useIsTimePassed'
import Background from '../../components/Background/Background'
import BackButton from '../../components/BackButton/BackButton'

type WaitForResultsPageParams = {
  answerData: AnswerData
  episodeData: EpisodeData
}

const Scorepage: FC<WaitForResultsPageParams> = ({ episodeData, answerData }) => {
  const navigate = useNavigate()
  const [episode, isEpisodeLoading] = episodeData
  const { answers, isLoading: isAnswersLoading } = answerData
  const isRevealTimePassed = useIsTimePassed(episode?.revealTime || null)

  const isLoading = isEpisodeLoading || isAnswersLoading

  if (isLoading) {
    return (
      <Background>
        <PageLoadingIndicator />
      </Background>
    )
  }

  const answerCount = answers.length
  const questionCount = getQuestions(episode)?.length

  return (
    <Background>
      <div className={styles.container}>
        <Lobby
          button={{
            isDisabled: !isRevealTimePassed,
            onClick: () => navigate(`${ROUTES.gamepage}/${episode?.uuid}${ROUTES.correctAnswers}`),
            text: strings.correctAnswers
          }}
          text={{
            title: strings.allQuestionsAnswered,
            currentScoreInfo: strings.youAnsweredXQuestions({ answerCount, questionCount }),
            gameTip: strings.CheckAndParticipate,
            ripOff: strings.hashtag
          }}
        />
        <BackButton toRoute={ROUTES.frontpage} noIcon>
          {strings.goBackToFrontpage}
        </BackButton>
      </div>
    </Background>
  )
}

export default Scorepage
