import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Question } from '../models/question'
import ROUTES from '../utils/routes'

type UseQuestionCarouselStateParams = {
  sections: Question[]
  jumpToSection?: number
}

export const useQuestionCarouselState = ({
  sections,
  jumpToSection
}: UseQuestionCarouselStateParams) => {
  const [currentSection, setCurrentSection] = React.useState<Question>()
  const [currentQuestion, setCurrentQuestion] = React.useState<Question>()
  const [isSectionLobby, setIsSectionLobby] = React.useState(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  React.useEffect(() => {
    setCurrentSection(section =>
      section ? sections.find(s => s.uuid === section.uuid) : sections[0]
    )
    setCurrentQuestion(question =>
      question
        ? sections.flatMap(section => section.children).find(q => q.uuid === question.uuid)
        : sections[0].children[0]
    )
  }, [sections])

  React.useEffect(() => {
    if (jumpToSection !== undefined) {
      const ongoingSection = sections.find(section => section.orderNumber === jumpToSection)
      setCurrentSection(ongoingSection)
      setCurrentQuestion(undefined)
      setIsSectionLobby(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jumpToSection])

  const onEnterSection = React.useCallback(() => {
    setCurrentQuestion(currentSection?.children[0])
    setIsSectionLobby(false)
  }, [currentSection])

  const onSkipSection = React.useCallback(() => {
    const hasNextSection = Boolean(currentSection && sections[currentSection.orderNumber])
    if (hasNextSection) {
      setIsSectionLobby(true)
      const nextSection = currentSection && sections[currentSection.orderNumber]
      setCurrentSection(nextSection)
      setCurrentQuestion(undefined)
    } else {
      navigate(`${pathname}${ROUTES.waitForResults}`)
    }
  }, [sections, currentSection, pathname, navigate])

  const onNextQuestion = React.useCallback(() => {
    const nextQuestion =
      currentSection && currentQuestion && currentSection.children[currentQuestion.orderNumber]
    if (nextQuestion) {
      setCurrentQuestion(nextQuestion)
    } else {
      onSkipSection()
    }
  }, [currentSection, currentQuestion, onSkipSection])

  return {
    currentSection,
    currentQuestion,
    isSectionLobby,
    onNextQuestion,
    onEnterSection,
    onSkipSection
  }
}
