.frontpage {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.frontpageButtonContainer {
  min-width: 220px;
}

.frontpageButtonContainer > button {
  margin-bottom: 24px;
}

.frontpageButton {
  margin-bottom: 16px;
}

.infoButton {
  align-self: flex-end;
}

.infoText {
  text-align: center;
  max-width: 320px;
  padding: 24px;
}
