import React, { FC, MouseEventHandler, ReactNode } from 'react'
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator'
import * as styles from './button.module.css'

type ButtonVariant = 'shinyGold' | 'dimGold' | 'transparent' | 'white' | 'text' | 'purple'

type ButtonProps = {
  variant?: ButtonVariant
  onClick?: MouseEventHandler<HTMLButtonElement>
  children?: ReactNode
  iconAfter?: ReactNode
  iconBefore?: ReactNode
  isDisabled?: boolean
  className?: string
  isLoading?: boolean
}

const getVariantClassName = (variant: ButtonVariant) => {
  switch (variant) {
    case 'shinyGold':
      return styles.shinyGold
    case 'dimGold':
      return styles.dimGold
    case 'transparent':
      return styles.transparent
    case 'white':
      return styles.white
    case 'text':
      return styles.text
    case 'purple':
      return styles.purple
  }
}

const Button: FC<ButtonProps> = ({
  onClick,
  children,
  iconAfter,
  iconBefore,
  isDisabled,
  className,
  isLoading,
  variant = 'shinyGold'
}) => {
  return (
    <button
      disabled={isDisabled || isLoading}
      className={`${styles.button} ${getVariantClassName(variant)} ${className ? className : ''}`}
      onClick={onClick}
    >
      <div className={styles.left}>{iconBefore}</div>
      <div>{isLoading ? <LoadingIndicator size="xs" /> : children}</div>
      <div className={styles.right}>{iconAfter}</div>
    </button>
  )
}

export default Button
