import React, { FC, ReactNode } from 'react'
import { ImsImage } from './ImsImage'

const shortcodeRegexp = /^\[(.*?)(?: (.*?))\]$/
const splitRegexp = /(\[.*?\]|\n\n)/

const getProps = (propsString: string): Record<string, string> =>
  propsString.split('|').reduce<Record<string, string>>((props, prop) => {
    const [key, value] = prop.split('=')
    props[key] = value
    return props
  }, {})

const formatShortcode = (
  code: string,
  propsString: string,
  key: string,
  disableImages: boolean
): ReactNode => {
  const props = getProps(propsString)
  if (code === 'IMS') {
    return disableImages ? null : <ImsImage key={key} id={props.id} aspect={props.aspect} />
  }
  console.warn(`Unknown shortcode: ${code}`)
  return null
}

const formatSegment =
  (disableImages: boolean) =>
  // eslint-disable-next-line react/display-name
  (s: string, i: number): ReactNode => {
    const match = s.match(shortcodeRegexp)
    if (match) {
      const [, code, props] = match
      return formatShortcode(code, props, `shortcode-${i}`, disableImages)
    } else if (s === '\n\n') {
      return <br key={`br-${i}`} />
    }
    return s
  }

type RichTextProps = {
  content?: string
  disableImages?: boolean
}

export const RichText: FC<RichTextProps> = ({ content, disableImages = false }) => {
  if (content === undefined) {
    return null
  }
  return <>{content.split(splitRegexp).map(formatSegment(disableImages))}</>
}
