.container {
  align-items: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  color: var(--color-black-1);
  justify-content: space-between;
}

.container > button {
  color: var(--color-black-1);
}
