import React, { FC } from 'react'
import * as styles from './ScoreSeparator.module.css'
import strings from '../../utils/strings'

const ScoreSeparator: FC = () => (
  <div className={styles.separator}>
    <div className={styles.dash} />
    <div className={styles.shareScore}>{strings.shareScore}</div>
    <div className={styles.dash} />
  </div>
)

export default ScoreSeparator
