import React, { FC, PropsWithChildren, useCallback } from 'react'
import * as styles from './webviewGuard.module.css'
import config from '../../config'
import Logo from '../Logo/Logo'
import strings from '../../utils/strings'
import { useTimeout } from '../../hooks/useTimeout'

declare global {
  interface Window {
    setWebviewGuardOff?: (() => void) | boolean
  }
}

const DOWNLOAD_YLE_APP_URL = 'https://sovellus.yle.fi/'

export const useWebviewGuard = () => {
  const [guard, setGuard] = React.useState<'ON' | 'OFF' | 'PENDING'>(
    config.useWebviewGuard ? (window.setWebviewGuardOff === true ? 'OFF' : 'PENDING') : 'OFF'
  )
  const onSetGuardOn = useCallback(() => {
    if (guard === 'PENDING') {
      setGuard('ON')
    }
  }, [guard])

  useTimeout(onSetGuardOn, 2500)

  if (window.setWebviewGuardOff === undefined) {
    window.setWebviewGuardOff = () => {
      setGuard('OFF')
    }
  }

  return guard
}

const WebviewGuard: FC<PropsWithChildren> = ({ children }) => {
  const guard = useWebviewGuard()

  if (guard === 'ON') {
    return (
      <div className={styles.container}>
        <Logo />
        <h1 className={styles.header}>{strings.downloadYleApp}</h1>
        <a className={styles.link} href={DOWNLOAD_YLE_APP_URL}>
          {DOWNLOAD_YLE_APP_URL}
        </a>
      </div>
    )
  } else if (guard === 'PENDING') {
    return null
  }

  return <>{children}</>
}

export default WebviewGuard
