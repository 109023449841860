import React, { FC } from 'react'
import { ChevronRight } from '@yleisradio/yds-icons-react'
import Button from '../../components/Button/Button'
import * as styles from './scorepage.module.css'
import ROUTES from '../../utils/routes'
import { useNavigate } from 'react-router-dom'
import { AnswerData } from '../../hooks/useAnswers'
import strings from '../../utils/strings'
import BackButton from '../../components/BackButton/BackButton'
import Background from '../../components/Background/Background'
import { PageLoadingIndicator } from '../../components/LoadingIndicator/LoadingIndicator'
import ScoreTicket from '../../components/ScoreTicket/ScoreTicket'
import ScoreSeparator from '../../components/ScoreSeparator/ScoreSeparator'
import { Result, useScores } from '../../hooks/useScores'
import ShareScore from '../../components/ShareScore/ShareScore'
import config from '../../config'

type ScorepageParams = {
  episodeId: string
  answerData: AnswerData
}

const getResultString = (score: number, result?: Result): string | undefined => {
  if (result === undefined) {
    return undefined
  } else if (score === 0) {
    return strings.zeroPoints(result.zeroPercentage)
  }
  return strings.betterThan(result.percentage)
}

const Scorepage: FC<ScorepageParams> = ({ episodeId, answerData }) => {
  const navigate = useNavigate()
  const { answers, isLoading: isAnswersLoading } = answerData
  const score = answers.reduce((score, answer) => score + answer.score, 0)
  const { result, isLoading: isScoresLoading } = useScores(
    episodeId,
    isAnswersLoading ? undefined : score
  )
  const isLoading = isAnswersLoading || isScoresLoading

  if (isLoading) {
    return (
      <Background overlay="purple">
        <PageLoadingIndicator color="white" />
      </Background>
    )
  }

  return (
    <Background overlay="purple">
      <div className={styles.scorepageContainer}>
        <div className={styles.scoreContainer}>
          <ScoreTicket score={score} maxScore={8} />
          <div className={styles.review}>{getResultString(score, result)}</div>
          <div className={styles.scoreFooter}>
            <Button
              className={styles.footerButton}
              iconAfter={<ChevronRight />}
              onClick={() => navigate(ROUTES.resultspage)}
              variant="transparent"
            >
              {strings.showResults}
            </Button>
          </div>
        </div>
        <ScoreSeparator />
        <ShareScore />
        <Button className={styles.footerButton} onClick={() => window.open(config.feedbackLink)}>
          {strings.giveFeedback}
        </Button>
        <BackButton toRoute={ROUTES.frontpage} noIcon>
          {strings.goBackToFrontpage}
        </BackButton>
      </div>
    </Background>
  )
}

export default Scorepage
