import React, { FC } from 'react'
import * as styles from './ShareScore.module.css'
import phoneticket from './phoneticket.webp'
import strings from '../../utils/strings'
import Button from '../Button/Button'

const ShareScore: FC = () => (
  <>
    <img className={styles.ticket} src={phoneticket} />
    <div className={styles.hashtag}>{strings.hashtag}</div>
    <div className={styles.text}>{strings.shareScore1}</div>
    <div className={styles.text}>{strings.shareScore2}</div>
    <div className={styles.text}>{strings.shareScore3}</div>
    <Button
      className={styles.link}
      variant="text"
      onClick={() => window.open('https://yle.fi/aihe/hengaillaan')}
    >
      yle.fi/aihe/hengaillaan
    </Button>
  </>
)

export default ShareScore
