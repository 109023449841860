.container {
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.holder {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.content {
  padding: 12px;
}
