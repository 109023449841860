import React, { FC } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as styles from './gamepage.module.css'
import QuestionCarousel from '../../components/QuestionCarousel/QuestionCarousel'
import { PageLoadingIndicator } from '../../components/LoadingIndicator/LoadingIndicator'
import { AnswerData } from '../../hooks/useAnswers'
import { EpisodeData } from '../../hooks/useEpisode'
import Background from '../../components/Background/Background'

type GamepageParams = {
  episodeData: EpisodeData
  answerData: AnswerData
}

const Gamepage: FC<GamepageParams> = ({ episodeData, answerData }) => {
  const [queryParams] = useSearchParams()
  const { answers, isLoading: isAnswersLoading, onPostAnswer } = answerData
  const [episode, isEpisodeLoading] = episodeData
  const isLoading = isAnswersLoading || isEpisodeLoading

  if (isLoading) {
    return (
      <Background overlay="white">
        <PageLoadingIndicator />
      </Background>
    )
  }

  return episode ? (
    <Background overlay="white">
      <div className={styles.gamepage}>
        <QuestionCarousel
          jumpToSection={
            queryParams.has('jumpToSection') ? Number(queryParams.get('jumpToSection')) : undefined
          }
          onAnswer={onPostAnswer}
          episode={episode}
          answers={answers}
        />
      </div>
    </Background>
  ) : null
}

export default Gamepage
