.scorepageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex: 1;
}

.review {
  font-weight: var(--font-weight--bold);
  font-size: 20px;
  text-align: center;
  width: 300px;
}

.scoreContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex: 0 0 65vh;
}

.headerText {
  font-size: 32px;
  text-transform: uppercase;
}

.scoreCircle {
  width: 128px;
  height: 128px;
  font-size: 64px;
  /*circle border*/
  border-radius: 50%;
  border-style: solid;
  border-width: 4px;
  /*center circle content*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  margin-top: 16px;
}

.scoreReview {
  text-align: center;
  margin-top: 16px;
}

.scoreFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerButton {
  margin-top: 8px;
  margin-bottom: 8px;
  align-self: center;
  width: auto;
  padding: 8px 32px;
}
