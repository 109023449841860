import React, { FC, useEffect, useState } from 'react'
import { ImsData, fetchImsData } from '../../api/json-storage-api/json-storage-api'
import config from '../../config'
import * as styles from './imsImage.module.css'

const getCropCoordinates = (imsData: ImsData, aspect?: string): string => {
  if (aspect === undefined) {
    return ''
  }
  const crop = imsData.crops.find(({ name }) => name === aspect)
  if (crop === undefined) {
    return ''
  }
  const { height, width, x, y } = crop.coordinates
  return `w_${width},h_${height},c_crop,x_${x},y_${y}/`
}

const createImsImageUrl = (imsData: ImsData, aspect?: string): string => {
  const { imsUrl } = config
  const { publicId, version } = imsData
  const crop = getCropCoordinates(imsData, aspect)
  const parameters = 'w_600,c_fill'

  return `${imsUrl}/${crop}${parameters}/v${version}/${publicId}.jpg`
}

type ImsImageProps = {
  id: string
  aspect?: string
}

export const ImsImage: FC<ImsImageProps> = ({ id, aspect }) => {
  const [imsData, setImsData] = useState<ImsData>()
  useEffect(() => {
    fetchImsData(id)
      .then(setImsData)
      .catch(() => setImsData(undefined))
  }, [id])
  if (imsData === undefined) {
    return null
  }
  const src = createImsImageUrl(imsData, aspect)
  return <img className={styles.imsImage} src={src} />
}
