const nthQuestion = (n: number) => {
  switch (n) {
    case 2:
      return 'toiselle'
    case 3:
      return 'kolmannelle'
    case 4:
      return 'neljännelle'
    default:
      return `${n.toString()}.`
  }
}

const nth = (n: number) => {
  switch (n) {
    case 1:
      return 'Ensimmäinen'
    case 2:
      return 'Toinen'
    case 3:
      return 'Kolmas'
    case 4:
      return 'Neljäs'
    default:
      return `${n.toString()}.`
  }
}

const scoreTitle = (n: number) => {
  switch (n) {
    default:
    case 0:
      return 'Salamatkustaja'
    case 1:
      return 'Seuramatkaaja'
    case 2:
      return 'Pakettimatkaaja'
    case 3:
      return 'Reppureissaaja'
    case 4:
      return 'Sohvasurffari'
    case 5:
      return 'Matkaopas'
    case 6:
      return 'Matkanjohtaja'
    case 7:
      return 'Jetsettaaja'
    case 8:
      return 'Maailmanmatkaaja'
  }
}

const percentage = (p: number) => `${Math.round(p * 100)}\u00A0%`

const strings = {
  allEpisodes: 'Menneet ja tulevat jaksot',
  chooseOption: 'Valitse yksi vaihtoehto',
  close: 'Sulje',
  goBack: 'Takaisin',
  goBackToFrontpage: 'Takaisin etusivulle',
  giveFeedback: 'Anna palautetta',
  giveFeedbackText:
    'Paina nappia ja lähetä meille terveisiä, miten voisimme parantaa Hengaillaan-kotipeliä.',
  nextQuestion: 'Eteenpäin',
  youMustLogin: 'Sinun täytyy kirjautua Yle Tunnuksella osallistuaksesi peliin.',
  lockAnswer: 'Lukitse vastaus',
  live: 'Live',
  pastEpisodes: 'Menneet jaksot',
  frontpageText:
    'Tervetuloa pelaamaan kotipeliä suoran lähetyksen aikana. Kotipelikysymykset alkavat aina uuden matkakohteen paljastumisen jälkeen. Seuraa Jenspan ohjeita lähetyksessä ja pelaa mukana.',
  play: 'Pelaa kotipeliä',
  info: 'Ohjeet ja anna palautetta',
  instructions: 'Kotipelin ohjeet',
  instructionText1:
    'Voit osallistua lauantai-iltaisin, suoran lähetyksen aikana Hengaillaan-visaan.',
  instructionText2:
    'Pelataksesi sinulla pitää olla älypuhelin, ladattu ja asennettu Yle-sovellus sekä sinun pitää olla kirjautunut Yle Tunnuksella sisään.',
  instructionText3:
    'Illan TV-jakson aikana kotipelikysymyksiä kännykkääsi tulee yhteensä kahdeksan kappaletta.',
  instructionText4: 'Kysymykset ovat samoja, mitä lähetyksessä esitetään studiokilpailijoille.',
  instructionText5:
    'Kysymykset aukeaa kännykkääsi pareittain sen jälkeen, kun ollaan arvuuteltu mihin kohteeseen ollaan junalla saavuttu.',
  instructionText6: 'Vastausaikaa on vain kysymysvideon loppuun asti - ole siis nopea!',
  instructionText7:
    'Oikeasta vastauksesta saat yhden pisteen, väärästä saat nolla pistettä. Illan pistepottisi paljastuu kotipelikysymysten jälkeen.',
  instructionText7b:
    'Lisää pisteesi mukaan koko kauden tulostaulukkoon. Kauden kärkipelaajat palkitaan.',
  instructionText8:
    'Jaa ruutukaappaus pelitulossivustasi julkisesti Instagramissa ja Facebookissa, liitä mukaan tunniste #hengaillaan ja olet mukana viikoittaisessa lippujen arvonnassa Hengaillaan -studiokatsomoon Tampereen Mediapoliksessa.',
  instructionText9: 'Arvonnan tarkemmat ohjeet ja ehdot osoitteessa ',
  departure: 'Lähtö',
  downloadYleApp: 'Lataa Yle-sovellus ja pelaa Hengaillaan-kotipeliä älypuhelimellasi.',
  timeFormat: 'DD.MM. klo HH:mm',
  toPlay: 'Pelaamaan',
  question: 'Kysymys',
  questionPair: 'Kysymyspari',
  upcomingEpisodes: 'Tulevat jaksot',
  showResults: 'Näytä tulostaulu',
  shareScore: 'Jaa tuloksesi',
  forward: 'Eteenpäin',
  timeEnded: 'Aika loppui!',
  youWereLate: 'Pahus! Et ehtinyt vastaamaan kaikkiin tämän kierroksen kysymyksiin.',
  postScoreToLeaderboard: 'Jaa tuloksesi pistetauluun',
  seeScoresAfterQuestions: 'Näet pisteesi vastattuasi kaikkiin kysymyksiin',
  questionWillOpenLater: 'Odotellaan hetkinen. Kysymykset avautuvat junan saavuttua asemalle.',
  yourScore: 'Pisteesi',
  correct: 'Oikein',
  game: 'Kotipeli',
  gameStartsSoon: 'Kotipeli alkaa aivan kohta!',
  gameHasStarted: 'Kotipeli on pelattavissa!',
  welcome: 'Tervetuloa pelaamaan Hengaillaan-kotipeliä',
  gameTip2:
    'Illan TV-jakson aikana kotipelikysymyksiä tulee yhteensä kahdeksan kappaletta. Kysymykset avautuvat pareittain aina sen jälkeen, kun juna on saapunut uuteen kaupunkiin.',
  gameTip3: 'Vastausaikaa on vain kohdevideon loppuun. Ole siis nopea!',
  answersSaved: 'Seuraava kysymyspari aukeaa uuden junamatkan jälkeen',
  gameIsOpen: 'Siirry pelaamaan - ole nopea, aikaa vastata on vain kysymysvideon ajan!',
  gameIsNotOpen: 'Kotipeli ei ole vielä pelattavissa',
  gameIsOn: 'Peli käynnissä!',
  gameIsOnNthQuestion: (n: number) => `Kotipeli on edennyt ${nthQuestion(n)} kierrokselle.`,
  moveToSection: 'Siirry kysymyspariin',
  allQuestionsAnswered: 'Kaikki kotipelin kysymykset on nyt pelattu',
  checkScore: 'Katso pisteesi',
  CheckAndParticipate:
    'Katso illan kokonaispisteesi, osallistu tulostauluun ja jaa tuloksesi somessa',
  hashtag: '#hengaillaan',
  youAnsweredXQuestions: ({
    answerCount,
    questionCount
  }: {
    answerCount: number
    questionCount: number | undefined
  }) => `Olet vastannut ${answerCount}/${questionCount} kotipelin kysymyksistä`,
  correctAnswers: 'Oikeat vastaukset',
  scoreboard: 'Tulostaulu',
  stillCalculating: 'Tulostasi lasketaan vielä, palaa hetken kuluttua',
  leaveLeaderboard: 'Poista osallistumisesi',
  joinLeaderboard: 'Osallistu tulostauluun',
  rank: 'Sija',
  player: 'Pelaaja',
  points: 'Pisteet',
  toLeaderboard: 'Pistetauluun',
  correctAnswer: 'Oikea vastaus',
  yourCorrectAnswer: 'Vastasit oikein!',
  yourIncorrectAnswer: 'Sinä vastasit',
  noAnswer: 'Et vastannut mitään',
  nthRound: (n: number) => `${nth(n)} kierros`,
  betterThan: (p: number) =>
    `Hyvin sä vedät! Onnistuit paremmin kuin ${percentage(p)} muista pelaajista!`,
  zeroPoints: (p: number) =>
    `Et ollut ainoa, myös ${percentage(p)} muista pelaajista jäi pisteittä.`,
  scoreTitle,
  shareScore1: 'Jaa ruutukaappaus tästä sivusta julkisesti Instagramissa tai Facebookissa.',
  shareScore2:
    'Liitä mukaan tunniste #hengaillaan ja olet mukana viikottaisessa lippujen arvonnassa Hengaillaan-studiokatsomoon Tampereen Mediapoliksessa.',
  shareScore3: 'Arvonnan tarkemmat ohjeet ja ehdot osoitteessa ',
  pointsInfo: 'Oikeasta vastauksesta saat yhden pisteen, väärästä saat nolla pistettä.'
}

export default strings
