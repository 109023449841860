import React, { FC } from 'react'
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom'
import ROUTES from '../utils/routes'
import Frontpage from './Frontpage/Frontpage'
import Resultspage from './Resultspage/Resultspage'
import Scorepage from './Scorepage/Scorepage'
import Gamepage from './Gamepage/Gamepage'
import WaitForResultsPage from './WaitForResultsPage/WaitForResultsPage'
import CorrectAnswersPage from './CorrectAnswersPage/CorrectAnswersPage'
import GameLobbyPage from './GameLobbyPage/GameLobbyPage'
import { useAnalytics } from '../hooks/useAnalytics'
import { useAnswers } from '../hooks/useAnswers'
import { useEpisode } from '../hooks/useEpisode'

const Episode = () => {
  const { episodeId } = useParams() as { episodeId: string }
  const answerData = useAnswers(episodeId)
  const episodeData = useEpisode(episodeId)

  return (
    <Routes>
      <Route path="/" element={<Gamepage answerData={answerData} episodeData={episodeData} />} />
      <Route
        path={ROUTES.gamelobby}
        element={
          <GameLobbyPage answerData={answerData} episodeData={episodeData} episodeId={episodeId} />
        }
      />
      <Route
        path={ROUTES.waitForResults}
        element={<WaitForResultsPage answerData={answerData} episodeData={episodeData} />}
      />
      <Route
        path={ROUTES.correctAnswers}
        element={
          <CorrectAnswersPage
            answerData={answerData}
            episodeData={episodeData}
            episodeId={episodeId}
          />
        }
      />
      <Route
        path={ROUTES.scorepage}
        element={<Scorepage episodeId={episodeId} answerData={answerData} />}
      />
    </Routes>
  )
}

const HengaillaanRoutes = () => {
  useAnalytics()

  return (
    <Routes>
      <Route path={ROUTES.frontpage} element={<Frontpage />} />
      <Route path={ROUTES.resultspage} element={<Resultspage />} />
      <Route path={`${ROUTES.gamepage}/:episodeId/*`} element={<Episode />} />
    </Routes>
  )
}

const Router: FC = () => (
  <BrowserRouter>
    <HengaillaanRoutes />
  </BrowserRouter>
)

export default Router
