import React from 'react'
import * as api from '../api/api-wrapper'
import { EpisodeScore } from '../models/playerScore'

const countScores = (scores: EpisodeScore[]): number =>
  scores.reduce((total, { count }) => total + count, 0)

export type Result = {
  percentage: number
  zeroPercentage: number
}

type UseScores = {
  result?: Result
  isLoading: boolean
}

export const useScores = (episodeId: string, playerScore?: number): UseScores => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [scores, setScores] = React.useState<EpisodeScore[]>([])
  const [result, setResult] = React.useState<Result>()

  React.useEffect(() => {
    const totalCount = countScores(scores)
    if (totalCount === 0 || playerScore === undefined) {
      setResult(undefined)
      return
    }
    const betterThanCount = countScores(scores.filter(({ score }) => score <= playerScore))
    const zeroCount = countScores(scores.filter(({ score }) => score === 0))
    setResult({
      percentage: betterThanCount / totalCount,
      zeroPercentage: zeroCount / totalCount
    })
  }, [scores, playerScore])

  React.useEffect(() => {
    const fetchScores = async () => {
      try {
        const scores = await api.fetchTvEpisodeScores(episodeId)
        setScores(scores)
      } catch (e) {
        // TODO: handle errors
        if (window.getEnv() === 'dev') {
          // eslint-disable-next-line no-console
          console.log(e)
        }
      } finally {
        setIsLoading(false)
      }
    }

    setIsLoading(true)
    setScores([])
    setResult(undefined)
    fetchScores()
  }, [episodeId])

  return { result, isLoading }
}
