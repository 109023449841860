
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-color: var(--yds-color-background-dialog-overlay);
  color: var(--yds-color-text-default);
  z-index: 2;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-shadow: var(--yds-shadow-md);
  background-color: var(--yds-color-background);
  width: 100%;
  padding: 24px;
  margin: 8px;
}

.trainInfoContainer {
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: var(--shadow-md);
  z-index: 2;
}

.trainInfoContent {
  background-color: var(--color-black-2);
  padding: 24px;
  margin: 24px;
  margin-top: auto;
  border-radius: 4px;
  max-width: 400px;
}

.trainInfoPanels  {
  display: flex;
  position: relative;
}

.trainInfoPanels::after  {
  content:'';
  display:block;
  box-shadow:inset 0 0 10px black;
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

.trainInfoLeftPanel {
  background-color: var(--color-black-3);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: var(--shadow-md);
  padding: 16px
}

.trainInfoButtonContainer {
  min-width: 200px;
  margin-bottom: auto;
}

.trainInfoRightPanel {
  background-color: var(--color-black-4);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.trainInfoRightPanelDivider {
  height: 2px;
  background-color: white;
}

.trainInfoClock {
  min-width: 48px;
}

.trainInfoText {
  color: white;
  font-weight: var(--font-weight--semibold);
  padding: 0 16px;
}
