
.infoModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.marginTop {
  margin-top: 16px;
}

.infoModalContent > p {
  margin: 6px 0;
  align-self: flex-start;
}

.infoModalHeader {
  text-align: center;
  align-self: flex-start;
}
