import React, { FC, useEffect } from 'react'
import { ChevronRight } from '@yleisradio/yds-icons-react'
import Button from '../Button/Button'
import * as styles from './lobby.module.css'
import DevNextButton from '../DevTools/DevNextButton'
import { scrollToEnd } from '../../utils/scroll'

type LobbyProps = {
  text: {
    title?: string
    currentScoreInfo?: string
    gameTip?: string
    ripOff?: string
    nextStepText?: string
  }
  button: {
    isDisabled: boolean
    text: string
    onClick: () => void
  }
}

const Lobby: FC<LobbyProps> = ({ button, text }) => {
  useEffect(() => {
    if (!button.isDisabled) {
      scrollToEnd()
    }
  }, [button.isDisabled])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.ticketGrid}>
          <div className={styles.ticketTitle}>{text.title}</div>
          <div className={styles.currentScoreInfo}>{text.currentScoreInfo}</div>

          <div className={styles.secondaryText}>{text.gameTip}</div>
          <div />
          <div className={styles.ripOff}>{text.ripOff}</div>
        </div>
        <DevNextButton onClick={button.onClick} />
      </div>
      <div className={styles.nextStepWrapper}>
        {text.nextStepText && <p className={styles.nextStepText}>{text.nextStepText}</p>}
        <Button
          className={styles.nextButton}
          iconAfter={<ChevronRight />}
          isDisabled={button.isDisabled}
          variant={'shinyGold'}
          onClick={button.onClick}
        >
          {button.text}
        </Button>
      </div>
    </div>
  )
}

export default Lobby
