import React, { FC } from 'react'
import { Button, ButtonDSProps } from '@yleisradio/yds-components-react'

type ButtonProps = ButtonDSProps & {
  className?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const YdsButton: FC<ButtonProps> = ({
  className,
  onClick,
  text,
  size = 'sm',
  isDisabled = false,
  loading = false,
  iconBefore,
  iconAfter,
  iconOnly = false,
  variant = 'primary'
}) => (
  <Button
    iconOnly={iconOnly}
    iconAfter={iconAfter}
    iconBefore={iconBefore}
    variant={variant}
    isDisabled={isDisabled}
    className={className}
    loading={loading}
    size={size}
    onClick={isDisabled ? undefined : onClick}
  >
    {text}
  </Button>
)

export default YdsButton
