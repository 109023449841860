.container {
  display: flex;
  flex-direction: column;
  margin: 8px;
}

.imageContainer {
  position: relative;
}

.liveImage {
  min-width: 60px;
}

.episode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.episodeName {
  font-size: 12px;
  font-weight: var(--font-weight--bold);
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.episodeNumber {
  font-size: 32px;
  font-weight: var(--font-weight--bold);
  margin: 0;
  padding: 0;
}

.liveText {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  line-height: 12px;
  font-weight: var(--font-weight--bold);
  color: var(--color-gray--live);
  font-size: 14px;
}

.isLive {
  color: var(--color-green--live);
  text-shadow: 0 0 2px var(--color-green--live);
}
