import config from '../../config'
import { HttpError } from '../tehtava-api/utils'

export type Crop = {
  aspect: number
  coordinates: {
    x: number
    y: number
    width: number
    height: number
  }
  name: string
}

export type ImsData = {
  crops: Crop[]
  publicId: string
  version: string
}

type ImsResponse = { data: ImsData }

export const fetchImsData = async (imageId: string): Promise<ImsData> => {
  const { appId, appKey, jsonStorageApiUrl } = config

  const imsDataUrl = `${jsonStorageApiUrl}/collection/ims-images-v1/document/36-${imageId}?app_id=${appId}&app_key=${appKey}`

  const response = await fetch(imsDataUrl)
  if (response.status >= 400) {
    throw new HttpError(`Failed to fetch IMS data for id ${imageId}`, response.status)
  }
  const { data }: ImsResponse = await response.json()
  return data
}
