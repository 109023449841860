import React from 'react'
import * as api from '../api/api-wrapper'
import { Episode } from '../models/episode'
import { useInterval } from './useInterval'

export type EpisodeData = [Episode | null, boolean]

const TIMER_MILLIS = 10000

export const useEpisode = (episodeId: string): EpisodeData => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [episode, setEpisode] = React.useState<Episode | null>(null)

  const fetchEpisode = React.useCallback(async () => {
    try {
      const episode = await api.fetchTvEpisode(episodeId)
      setEpisode(episode)
    } catch (e) {
      // TODO: handle errors
      if (window.getEnv() === 'dev') {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    } finally {
      setIsLoading(false)
    }
  }, [episodeId])

  useInterval(fetchEpisode, TIMER_MILLIS)

  React.useEffect(() => {
    setIsLoading(true)
    setEpisode(null)
    fetchEpisode()
  }, [fetchEpisode])

  return [episode, isLoading]
}
