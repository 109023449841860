import { Answer } from '../models/answer'
import { Episode } from '../models/episode'
import { Participation } from '../models/participation'
import { EpisodeScore, PlayerScore } from '../models/playerScore'
import { Series } from '../models/series'
import * as tehtavaApi from './tehtava-api/tehtava-api'
import * as vuorovaikutusApi from './vuorovaikutus-api/vuorovaikutus-api'
import config from '../config'

const USE_VUOROVAIKUTUS_API = config.useVuorovaikutusApi

export const fetchSeries: (uuid: string, isLoggedIn: boolean) => Promise<Series> =
  USE_VUOROVAIKUTUS_API ? vuorovaikutusApi.fetchSeries : tehtavaApi.fetchSeries

export const fetchTvEpisode: (uuid: string) => Promise<Episode> = USE_VUOROVAIKUTUS_API
  ? vuorovaikutusApi.fetchTvEpisode
  : tehtavaApi.fetchTvEpisode

export const fetchAnswers: (uuid: string) => Promise<Answer[]> = USE_VUOROVAIKUTUS_API
  ? vuorovaikutusApi.fetchAnswers
  : tehtavaApi.fetchAnswers

export const postAnswer: (answer: Answer) => Promise<Response> = USE_VUOROVAIKUTUS_API
  ? vuorovaikutusApi.postAnswer
  : (answer: Answer) => {
      return tehtavaApi.postAnswers([answer])
    }

export const fetchTvEpisodeScores: (uuid: string) => Promise<EpisodeScore[]> = USE_VUOROVAIKUTUS_API
  ? vuorovaikutusApi.fetchTvEpisodeScores
  : tehtavaApi.fetchTvEpisodeScores

export const postParticipation: tehtavaApi.PostParticipation = USE_VUOROVAIKUTUS_API
  ? vuorovaikutusApi.postParticipation
  : tehtavaApi.postParticipation

export const fetchParticipation: (seriesUuid: string) => Promise<Participation> =
  USE_VUOROVAIKUTUS_API ? vuorovaikutusApi.fetchParticipation : tehtavaApi.fetchParticipation

export const fetchLeaderboard: (uuid: string) => Promise<PlayerScore[]> = USE_VUOROVAIKUTUS_API
  ? vuorovaikutusApi.fetchLeaderboard
  : tehtavaApi.fetchLeaderboard
