export type UserInfo = {
  userId: string
  username: string
  initials: string
  nick: string
  latestTermsOfServiceAccepted: boolean
}

export type TunnusState =
  | {
      loggedIn: null
      user: null
    }
  | {
      loggedIn: false
      user: null
    }
  | {
      loggedIn: true
      user: UserInfo
    }

export type TunnusSubscription = {
  unsubscribe(): void
}

export type TunnusInstance = {
  logIn(): Promise<void>
  logOut(): Promise<void>
  state: {
    subscribe(callback: (state: TunnusState) => void): TunnusSubscription
  }
}

type TunnusOptions = {
  trackingAppName: string
  initiatingApp: string
  elementId: string
  language?: 'fi' | 'sv' | 'en'
  environment: 'test' | 'production' | 'local'
}

declare global {
  interface Window {
    yleTunnus?(options: TunnusOptions): Promise<TunnusInstance>
  }
}

let yleTunnus: TunnusInstance
let subscription: TunnusSubscription

export const yleTunnusLogIn = async () => yleTunnus?.logIn()
export const yleTunnusLogOut = async () => yleTunnus?.logOut()

const initializeAndSubscribe = async (onChange: (state: TunnusState) => void): Promise<void> => {
  if (window.yleTunnus) {
    yleTunnus = await window.yleTunnus({
      trackingAppName: 'hengaillaan',
      initiatingApp: 'hengaillaan',
      environment: window.getEnv() === 'prod' ? 'production' : 'test',
      elementId: 'yle-tunnus-ui'
    })

    subscription?.unsubscribe()
    subscription = yleTunnus?.state.subscribe(onChange)
  } else {
    throw Error('Failed to initialize Yle tunnus SDK')
  }
}

const subscribeToYleTunnus = (onChange: (state: TunnusState) => void) => {
  if (window?.yleTunnus) {
    initializeAndSubscribe(onChange)
  } else {
    document
      ?.getElementById('yle-tunnus-sdk')
      ?.addEventListener('load', () => initializeAndSubscribe(onChange))
  }
}

export default subscribeToYleTunnus
