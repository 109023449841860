.container {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  padding: 0 16px;
}

.header {
  margin-top: 64px;
  text-align: center;
}

.link {
  color: var(--text-primary);
  font-size: 18px;
}
