.ticket {
  width: 120px;
  margin: 16px 0;
}

.hashtag {
  font-weight: var(--font-weight--bold);
  font-size: 20px;
  margin: 16px 0;
}

.text {
  margin: 16px 24px;
  text-align: center;
}

.link {
  font-weight: var(--font-weight--bold);
  margin-bottom: 24px;
  align-self: center;
}