/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { SerieEpisode } from '../models/serieEpisode'
import { Series } from '../models/series'
import * as api from '../api/api-wrapper'
import config from '../config'
import '../utils/dayjs'
import dayjs from 'dayjs'
import { useIsTimePassed } from './useIsTimePassed'
import { useInterval } from './useInterval'

type UseSeries = {
  isLoading: boolean
  nextEpisode: SerieEpisode | null
  allEpisodes: SerieEpisode[]
  isLive: boolean
}

const EPISODE_OPEN_MINUTES = 110
const FIFTEEN_SECONDS_IN_MILLIS = 15000

const getNextEpisode = (series: Series) => {
  const sortedEpisodes = series?.exams
    .filter(e => e.startTime !== null)
    .sort((a, b) => a.startTime!.valueOf() - b.startTime!.valueOf())
  const now = dayjs()
  const nextEpisode = sortedEpisodes.find(e =>
    e.startTime!.isAfter(now.subtract(EPISODE_OPEN_MINUTES, 'minutes'))
  )
  return { nextEpisode, allEpisodes: sortedEpisodes }
}

export const useSeries = (): UseSeries => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [nextEpisode, setNextEpisode] = React.useState<SerieEpisode | null>(null)
  const [allEpisodes, setAllEpisodes] = React.useState<SerieEpisode[]>([])
  // Episode is live 10 minutes before the show
  const startTimePassed = useIsTimePassed(nextEpisode?.startTime?.subtract(10, 'minutes') || null)
  const endTimePassed = useIsTimePassed(nextEpisode?.endTime || null)
  const isLive = startTimePassed && !endTimePassed

  const onFetchSeries = React.useCallback(() => {
    api
      .fetchSeries(config.tehtavaSeriesId, false)
      .then(getNextEpisode)
      .then(({ nextEpisode, allEpisodes }) => {
        setNextEpisode(nextEpisode || null)
        setAllEpisodes(allEpisodes)
      })
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useInterval(onFetchSeries, FIFTEEN_SECONDS_IN_MILLIS)

  React.useEffect(() => {
    onFetchSeries()
  }, [onFetchSeries])

  return {
    isLoading,
    nextEpisode,
    allEpisodes,
    isLive
  }
}
