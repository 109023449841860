import React from 'react'

export const useTimeout = (onTimeout: () => void, milliseconds: number) => {
  React.useEffect(() => {
    let id: number | null = null
    if (milliseconds > 0) {
      id = window.setTimeout(onTimeout, milliseconds)
    }
    return () => {
      if (id !== null) {
        window.clearTimeout(id)
      }
    }
  }, [onTimeout, milliseconds])
}
