.xs {
  width: 24px;
  height: 24px;
}

.sm {
  width: 32px;
  height: 32px;
}

.md {
  width: 64px;
  height: 64px;
}

.lg {
  width: 128px;
  height: 128px;
}

.gray {
  color: var(--color-black-4);
}

.white {
  color: var(--text-primary);
}

.loadingIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.loadingIndicator > svg {
  animation: rotate ease-in-out 1500ms infinite;
}

@keyframes fadein { 
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.pageLoadingIndicator {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: 1s fadein .1s forwards;
}
