import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

declare global {
  interface Window {
    yleAnalytics?: {
      trackPage: (params?: { pageName?: string; labels?: Record<string, string> }) => void
    }
  }
}

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

const waitForAnalytics = async (): Promise<boolean> => {
  for (let times = 5; times >= 1; times--) {
    if (window.yleAnalytics) {
      return true
    }

    await sleep(200)
  }

  return false
}

export const useAnalytics = () => {
  const location = useLocation()

  useEffect(() => {
    const trackPage = async () => {
      const hasAnalytics = await waitForAnalytics()
      if (hasAnalytics) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        window.yleAnalytics!.trackPage({ labels: {} })
      } else {
        console.error('Yle analytics SDK not found')
      }
    }

    trackPage()
  }, [location])
}
