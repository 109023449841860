
.devtools {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: black;
  align-items: center;
}

.devtoolItem {
  margin: 4px 2px;
}
