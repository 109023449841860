import { Dayjs } from 'dayjs'
import { Episode } from '../models/episode'
import { Question } from '../models/question'

/* Get the actual questions (leaf nodes) from inside the sections */
export const getQuestions = (episode: Episode | null) => {
  return episode?.questions.flatMap(q => q.children)
}

/** Return first section that matches the condition "endTime is not passed" or undefined if condition is not met */
export const getOngoingSection = ({ sections, now }: { sections?: Question[]; now: Dayjs }) => {
  return sections?.find(
    section => section.settings.endTime === null || now.isBefore(section.settings.endTime)
  )
}
