import React, { FC, MouseEventHandler, ReactNode } from 'react'
import Button from '../Button/Button'
import * as styles from './toggleButton.module.css'

type ToggleButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>
  children?: ReactNode
  isPressed?: boolean
  isDisabled?: boolean
}

const ToggleButton: FC<ToggleButtonProps> = ({ isPressed, ...props }) => {
  return (
    <Button className={styles.toggleButton} variant={isPressed ? 'purple' : 'dimGold'} {...props} />
  )
}

export default ToggleButton
