import React, { useCallback, useEffect } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useInterval } from './useInterval'

export const useIsTimePassed = (date: Dayjs | null) => {
  const [isTimePassed, setIsTimePassed] = React.useState(false)

  const onTimeout = useCallback(() => {
    setIsTimePassed(date ? dayjs().isAfter(date) : false)
  }, [date])

  useEffect(() => {
    onTimeout()
  }, [onTimeout])

  useInterval(onTimeout, 1000)

  return isTimePassed
}
