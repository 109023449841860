import React, { FC, useContext, useState } from 'react'
import * as styles from './devtools.module.css'
import { yleTunnusLogIn, yleTunnusLogOut } from '../../state/yleTunnus/yleTunnus'
import YleTunnusContext from '../../state/yleTunnus/context'

const DevTools: FC = () => {
  const yleTunnus = useContext(YleTunnusContext)
  const [isShown, setIsShown] = useState(true)

  if (!isShown) {
    return null
  }

  return (
    <div className={styles.devtools}>
      <span className={styles.devtoolItem}>DevTools</span>
      {yleTunnus.loggedIn ? (
        <button
          className={styles.devtoolItem}
          type="button"
          onClick={() => {
            yleTunnusLogOut()
          }}
        >
          LogOut
        </button>
      ) : (
        <button
          className={styles.devtoolItem}
          type="button"
          onClick={() => {
            yleTunnusLogIn()
          }}
        >
          LogIn
        </button>
      )}
      <button
        className={styles.devtoolItem}
        onClick={() => {
          if (typeof window.setWebviewGuardOff === 'function') {
            window.setWebviewGuardOff()
          }
        }}
      >
        Webview Guard Off
      </button>
      <button className={styles.devtoolItem} onClick={() => setIsShown(false)}>
        Close
      </button>
    </div>
  )
}

export default DevTools
