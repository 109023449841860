import React, { FC, ReactNode } from 'react'
import Square from '../Square/Square'
import * as styles from './signBoard.module.css'

type SignBoardProps = {
  children?: ReactNode
  className?: string
}

const SignBoard: FC<SignBoardProps> = ({ children, className }) => {
  return (
    <div className={`${styles.container} ${className ? className : ''}`}>
      <div className={styles.holder}>
        <Square shape="sharp" />
        <Square shape="sharp" />
      </div>
      <Square shape="rounded" hasShadow>
        <div className={styles.content}>{children}</div>
      </Square>
    </div>
  )
}

export default SignBoard
