.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nextStepWrapper {
  margin-top: auto;
}

.nextButton {
  width: 100%;
}

.nextStepText {
  font-weight: var(--font-weight--bold);
  font-size: 14px;
  margin: 16px auto;
  text-align: center;
  width: 75%;
}

.ticketTitle {
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  margin: auto;
  padding: 5px 5px;
  font-weight: bolder;
}

.currentScoreInfo {
  color: white;
  text-align: center;
  margin: auto;
  padding: 5px min(5vw, 25px);
  font-size: 16px;
}

.secondaryText {
  color: white;
  font-weight: var(--font-weight--bold);
  text-align: center;
  font-size: 14px;
  margin: auto;
  padding: 5px 5px;
}

.ripOff {
  color: white;
  font-weight: var(--font-weight--bold);
  font-size: 18px;
  margin: auto;
}

@media (max-width: 341px) {
  .ticketTitle {
    font-size: 14px;
  }
  .secondaryText {
    font-size: 12px;
  }
  .currentScoreInfo {
    font-size: 14px;
  }
}

.ticketGrid {
  background-image: url('../../styles/img/kotipeli-kuva-lippu-green2.webp');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  box-sizing: border-box;
  padding: min(5vw, 35px) min(7vw, 39px) min(7vw, 50px);
  display: grid;
  position: relative;
  width: 75vw;
  height: 96vw;
  max-width: 500px;
  max-height: 640px;
  grid-template-rows: 25fr 21fr 20fr 4fr 14fr;
}
