import React, { FC, useState } from 'react'
import * as styles from './questionCarousel.module.css'
import Question from '../Question/Question'
import Lobby from '../Lobby/Lobby'
import { Answer } from '../../models/answer'
import { useQuestionCarouselState } from '../../hooks/useQuestionCarouselState'
import { Episode } from '../../models/episode'
import strings from '../../utils/strings'
import { UnsavedAnswer } from '../../hooks/useAnswers'
import { getQuestions } from '../../utils/episodes'
import { useIsTimePassed } from '../../hooks/useIsTimePassed'
import TimeEndedModal from '../TimeEndedModal/TimeEndedModal'
import { HttpError } from '../../api/tehtava-api/utils'
import SignBoard from '../SignBoard/SignBoard'
import BackButton from '../BackButton/BackButton'
import ROUTES from '../../utils/routes'

type QuestionCarouselProps = {
  episode: Episode
  answers: Answer[]
  onAnswer: (answer: UnsavedAnswer) => Promise<void>
  jumpToSection?: number
}

const sleep = (milliseconds: number) => {
  return new Promise(resolve => window.setTimeout(() => resolve(true), milliseconds))
}

const QuestionCarousel: FC<QuestionCarouselProps> = ({
  episode,
  answers,
  onAnswer,
  jumpToSection
}) => {
  const {
    currentSection,
    currentQuestion,
    isSectionLobby,
    onNextQuestion,
    onEnterSection,
    onSkipSection
  } = useQuestionCarouselState({ sections: episode.questions, jumpToSection })
  const [sectionClosedBeforeSubmit, setSectionClosedBeforeSubmit] = useState<boolean>(false)
  const isTimeStarted = useIsTimePassed(currentSection?.settings.startTime || null)
  const isTimeEnded = useIsTimePassed(currentSection?.settings.endTime || null)

  const handleSkipSection = () => {
    setSectionClosedBeforeSubmit(false)
    onSkipSection()
  }

  const onAnswerQuestion = async (answer: UnsavedAnswer) => {
    try {
      await onAnswer(answer)
      setTimeout(onNextQuestion, 1500)
    } catch (e) {
      if (e instanceof HttpError && e.statusCode === 403) {
        setSectionClosedBeforeSubmit(true)
      } else {
        console.error(e)
        await sleep(5000)
      }
    }
  }

  return (
    <div className={styles.container}>
      {isSectionLobby && (
        <>
          <Lobby
            text={{
              title: strings.answersSaved,
              currentScoreInfo: strings.youAnsweredXQuestions({
                answerCount: answers.length,
                questionCount: getQuestions(episode)?.length
              }),
              gameTip: strings.seeScoresAfterQuestions,
              nextStepText: !isTimeStarted ? strings.questionWillOpenLater : strings.gameIsOpen
            }}
            button={{
              onClick: onEnterSection,
              isDisabled: !isTimeStarted,
              text: strings.nextQuestion
            }}
          />
          <BackButton toRoute={ROUTES.frontpage} noIcon>
            {strings.goBackToFrontpage}
          </BackButton>
        </>
      )}
      {!isSectionLobby && currentSection && currentQuestion && (
        <>
          <SignBoard className={styles.signBoard}>
            <div className={styles.signBoardContent}>
              <div className={styles.signBoardContentTop}>
                <h1 className={styles.header1}>{strings.nthRound(currentSection.orderNumber)}</h1>
                <h2
                  className={styles.header2}
                >{`${strings.question} ${currentQuestion.orderNumber}/${currentSection.children.length}`}</h2>
              </div>
              <div className={styles.separator} />
              <div className={styles.signBoardContentBottom}>
                <h3 className={styles.header3}>{currentSection.mainText}</h3>
              </div>
            </div>
          </SignBoard>
          <Question
            section={currentSection}
            question={currentQuestion}
            answer={answers.find(a => a.questionUuid === currentQuestion.uuid)}
            onAnswer={onAnswerQuestion}
            onSkipQuestion={onNextQuestion}
          />

          {(isTimeEnded || sectionClosedBeforeSubmit) && (
            <TimeEndedModal onConfirm={handleSkipSection} />
          )}
        </>
      )}
    </div>
  )
}

export default QuestionCarousel
