import React, { useEffect } from 'react'
import * as api from '../api/api-wrapper'

export const useParticipation = (
  seriesId: string
): {
  participate: boolean | null
  postParticipation: (participate: boolean) => Promise<boolean>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [participate, setParticipate] = React.useState<boolean | null>(null)

  useEffect(() => {
    const fetchParticipation = async () => {
      try {
        const participation = await api.fetchParticipation(seriesId)
        setParticipate(participation.participate)
      } catch (e) {
        // TODO: handle errors
        if (window.getEnv() === 'dev') {
          // eslint-disable-next-line no-console
          console.log(e)
        }
      } finally {
        setIsLoading(false)
      }
    }

    setIsLoading(true)
    fetchParticipation()
  }, [seriesId])

  const postParticipation = (participate: boolean) =>
    api.postParticipation({ uuid: seriesId, participate }).then(participation => {
      setParticipate(participation.participate)
      return participation.participate
    })

  return { participate, postParticipation, isLoading }
}
