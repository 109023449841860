import React, { FC } from 'react'
import strings from '../../utils/strings'
import * as styles from './loginPlease.module.css'
import Background from '../Background/Background'

const LoginPlease: FC = () => (
  <Background overlay="purple" hasBlur hasGoldenEdge>
    <div className={styles.loginPlease}>
      <div className={styles.arrow} />
      <div className={styles.text}>{strings.youMustLogin}</div>
      <div className={styles.blank} />
    </div>
  </Background>
)

export default LoginPlease
