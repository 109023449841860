.ticket {
  background: url(scoreticket-green.webp) no-repeat;
  background-size: contain;
  display: grid;
  width: 300px;
  height: 357px;
  grid-template-rows: 20fr 50fr 167fr 60fr 40fr 20fr;
  align-items: center;
}

.header {
  text-align: center;
  font-weight: var(--font-weight--bold);
  font-size: 24px;
}

.score {
  text-align: center;
  font-weight: var(--font-weight--bold);
  font-size: 100px;
  line-height: 100px;
}

.footer {
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
}
