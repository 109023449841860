.answer {
  color: black;
  border-radius: 4px;
  text-align: center;
  padding: 4px;
  align-self: stretch;
  font-weight: var(--font-weight--semibold);
}

.correctAnswer {
  background: linear-gradient(to bottom right, white, var(--color-beige-3), var(--color-beige-2));
  box-shadow: 0 0 10px 1px var(--color-beige-3);
}

.yourCorrectAnswer {
  background: linear-gradient(to bottom right, white, var(--color-green--live));
  box-shadow: 0 0 10px 1px var(--color-green--live);
}

.incorrectAnswer {
  background: linear-gradient(to bottom right, white, var(--color-red-incorrect));
}

.answersContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  margin: 12px 0;
  justify-items: stretch;
  align-self: stretch;
}

.answerContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.answerHeader {
  font-weight: var(--font-weight--bold);
  margin-bottom: 12px;
  text-align: center;
}

.questionContainer {
  margin-top: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to bottom left, #0F3811, #000000) padding-box, linear-gradient(to bottom right, var(--color-beige-3), var(--color-beige-2), var(--color-beige-1)) border-box;
  border: 2px solid transparent;
  border-radius: 20px;
}

.questionHeader {
  text-transform: uppercase;
}

.pageHeaderText {
  text-align: center;
  text-transform: uppercase;
  margin: 8px 0 0;
  font-size: 14px;
}

.page {
  padding: 0 12px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  background: linear-gradient(to left, #0F3811, #000000);
}

.nextPageButton {
  margin: 12px 0;
  text-transform: uppercase;
}
