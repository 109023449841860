import React, { FC } from 'react'
import * as styles from './devnextbutton.module.css'

type DevNextButtonProps = {
  onClick: () => void
}

const DevNextButton: FC<DevNextButtonProps> = ({ onClick }) => {
  return window.getEnv() === 'dev' ? (
    <div className={styles.devNext} onClick={onClick}>
      Seuraava (dev)
    </div>
  ) : null
}

export default DevNextButton
