import { ChevronRight } from '@yleisradio/yds-icons-react'
import React, { FC } from 'react'
import strings from '../../utils/strings'
import Button from '../Button/Button'
import { TrainInfoModal } from '../Modal/Modal'
import * as styles from './timeEndedModal.module.css'

type TimeEndedModalProps = {
  onConfirm: () => void
}

const TimeEndedModal: FC<TimeEndedModalProps> = ({ onConfirm }) => {
  return (
    <TrainInfoModal
      button={
        <Button variant="white" iconAfter={<ChevronRight />} onClick={onConfirm}>
          {strings.forward}
        </Button>
      }
      header={<h2 className={styles.timeEndedHeader}>{strings.timeEnded}</h2>}
      content={<p>{strings.youWereLate}</p>}
    />
  )
}

export default TimeEndedModal
