.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.optionContainer {
  display: flex;
  flex-direction: column;
}

.option {
  margin-bottom: 16px;
}

.questionText {
  margin: 32px 0;
  text-align: center;
  font-weight: var(--font-weight--bold);
  font-size: 18px;
}

.hintText {
  text-align: center;
  font-weight: var(--font-weight--semibold);
  min-height: 22px;
}

.center {
  align-self: center;
  width: 200px;
}

.devNext {
  align-self: center;
  cursor: pointer;
  text-decoration: underline;
  font-size: 8px;
}

.sectionDescription {
  text-align: center;
  margin: 16px 0;
}
