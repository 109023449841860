import { toCamel, toSnake } from 'convert-keys'
import config from '../../config'
import { Answer } from '../../models/answer'
import { Episode } from '../../models/episode'
import { Participation } from '../../models/participation'
import { EpisodeScore, PlayerScore } from '../../models/playerScore'
import { Series } from '../../models/series'
import {
  RawEpisode,
  RawLeaderboard,
  RawSeries,
  formatEpisode,
  formatSeries,
  handleErrors,
  tehtavaFetch
} from './utils'

const POST_HEADERS = { 'Content-Type': 'application/json; charset=utf-8' }

export const fetchTvEpisode = async (uuid: string): Promise<Episode> => {
  const tehtavaEndpointUrl = `${config.tehtavaApiUrl}/public/exams.json?yleid=47-${uuid}`
  const rawEpisodes = await tehtavaFetch<RawEpisode>(tehtavaEndpointUrl, {
    method: 'GET',
    credentials: 'include'
  })

  return formatEpisode(rawEpisodes[0]) as Episode
}

export const fetchSeries = async (uuid: string, isLoggedIn: boolean): Promise<Series> => {
  const url = isLoggedIn
    ? `${config.tehtavaApiUrl}/answers/series?series_uuids=${uuid}`
    : `${config.tehtavaApiUrl}/public/series?series_uuids=${uuid}`
  const rawSeries = await tehtavaFetch<RawSeries>(url, {
    method: 'GET',
    credentials: 'include'
  })
  return formatSeries(rawSeries[0])
}

export const postAnswers = async (answers: Answer[]): Promise<Response> => {
  const url = `${config.tehtavaApiUrl}/answers/hengaillaan`
  const body = JSON.stringify(toSnake(answers))
  return handleErrors(
    await fetch(url, {
      method: 'POST',
      headers: POST_HEADERS,
      credentials: 'include',
      body
    })
  )
}

export const fetchAnswers = (uuid: string): Promise<Answer[]> => {
  const url = `${config.tehtavaApiUrl}/answers/exam/${uuid}/hengaillaan`
  return tehtavaFetch<Answer>(url, {
    method: 'GET',
    credentials: 'include'
  })
}

export const fetchTvEpisodeScores = (uuid: string): Promise<EpisodeScore[]> => {
  const url = `${config.tehtavaApiUrl}/public/scores.json?exam_uuid=${uuid}`
  return tehtavaFetch(url, {
    method: 'GET',
    credentials: 'include'
  })
}

export type PostParticipation = (participation: Participation) => Promise<Participation>

export const postParticipation: PostParticipation = async participation => {
  const url = `${config.tehtavaApiUrl}/answers/participate`
  const body = JSON.stringify(toSnake([participation]))
  const response = handleErrors(
    await fetch(url, {
      method: 'POST',
      headers: POST_HEADERS,
      credentials: 'include',
      body
    })
  )
  const responseBody: { participate: Participation } = toCamel(await response.json())
  return responseBody.participate
}

export const fetchParticipation = async (seriesUuid: string): Promise<Participation> => {
  const url = `${config.tehtavaApiUrl}/answers/participate?uuid=${seriesUuid}`
  const response = handleErrors(
    await fetch(url, {
      method: 'GET',
      credentials: 'include'
    })
  )
  const responseBody: Participation = toCamel(await response.json())
  return responseBody
}

// Fetch leaderboard for the whole competition. All questions belong to a single serie.
export const fetchLeaderboard = async (uuid: string): Promise<PlayerScore[]> => {
  const url = `${config.tehtavaApiUrl}/public/leaderboard-hengaillaan/${uuid}.json`

  const response = handleErrors(
    await fetch(url, {
      method: 'GET',
      credentials: 'include'
    })
  )

  const responseBody: RawLeaderboard = toCamel(await response.json())

  return responseBody.leaderboard
}
