const ROUTES = {
  frontpage: '/',
  gamepage: '/game',
  gamelobby: '/lobby',
  scorepage: '/score',
  correctAnswers: '/correctAnswers',
  waitForResults: '/waitForResults',
  resultspage: '/results'
}

export default ROUTES
