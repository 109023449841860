import React, { FC } from 'react'
import * as styles from './loadingIndicator.module.css'

type Size = 'sm' | 'md' | 'lg' | 'xs'

type LoadingIndicatorColor = 'white' | 'gray'

type LoadingIndicatorProps = {
  size: Size
  color?: LoadingIndicatorColor
}

type PageLoadingIndicatorProps = {
  color?: LoadingIndicatorColor
}

const getSizeClassName = (size: Size) => {
  switch (size) {
    case 'lg':
      return styles.lg
    case 'md':
      return styles.md
    case 'sm':
      return styles.sm
    case 'xs':
      return styles.xs
  }
}

const getColorClassName = (color: LoadingIndicatorColor) => {
  switch (color) {
    case 'white':
      return styles.white
    case 'gray':
      return styles.gray
  }
}

const LoadingIndicator: FC<LoadingIndicatorProps> = ({ size, color = 'gray' }) => (
  <span
    className={`${styles.loadingIndicator} ${getSizeClassName(size)} ${getColorClassName(color)}`}
  >
    <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.5 12C22.5 10.0222 21.9135 8.08879 20.8147 6.4443C19.7159 4.79981 18.1541 3.51808 16.3268 2.7612C14.4996 2.00433 12.4889 1.80629 10.5491 2.19215C8.60929 2.578 6.82746 3.53041 5.42893 4.92893C4.03041 6.32746 3.078 8.10929 2.69215 10.0491C2.30629 11.9889 2.50433 13.9996 3.26121 15.8268C4.01808 17.6541 5.29981 19.2159 6.9443 20.3147C8.58879 21.4135 10.5222 22 12.5 22"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
)

export const PageLoadingIndicator: FC<PageLoadingIndicatorProps> = ({ color = 'gray' }) => {
  return (
    <div className={styles.pageLoadingIndicator}>
      <LoadingIndicator color={color} size="lg" />
    </div>
  )
}

export default LoadingIndicator
