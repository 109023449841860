import { toCamel } from 'convert-keys'
import { Episode } from '../../models/episode'
import { Question } from '../../models/question'
import { PlayerScore } from '../../models/playerScore'
import dayjs from 'dayjs'
import { SerieEpisode } from '../../models/serieEpisode'
import { Series } from '../../models/series'

export type RawEpisode = Omit<Episode, 'startTime' | 'endTime'> & {
  classification: {
    revealTime: string
    startTime: string
    endTime: string
  }
  questions: RawQuestion[]
}

type RawSerieEpisode = Omit<RawEpisode, 'questions' | 'classification'> & {
  startTime: string
  endTime: string
}

export type RawSeries = { exams: RawSerieEpisode[] }

export type RawQuestion = Omit<Question, 'settings'> & {
  settings: {
    startTime?: string
    endTime?: string
    points?: string
  }
}

export type RawLeaderboard = { seriesUuid: string; leaderboard: PlayerScore[] }
export class HttpError extends Error {
  constructor(message: string, public statusCode: number) {
    super(message)
    this.name = 'HttpError'
  }
}

type TehtavaResponse<T> = {
  meta: {
    count: number
  }
  data: T[]
}

export const handleErrors = (res: Response): Response => {
  if (res.status >= 400) {
    throw new HttpError(
      `Fetch to '${res.url}' failed, status ${res.status} - '${res.statusText}'`,
      res.status
    )
  }
  return res
}

export const tehtavaFetch = async <T>(url: string, request: RequestInit): Promise<T[]> => {
  const response = handleErrors(await fetch(url, request))
  const body: TehtavaResponse<T> = toCamel(await response.json())
  return body.data
}

/**
 * Transform episode questions from a flat list into a nested tree
 */
const formatQuestions = (questions: RawQuestion[]): Question[] => {
  const sections = questions.filter(q => q.questionType === 'SECTION')
  const formatSettings = (q: RawQuestion): Question => ({
    ...q,
    settings: {
      startTime: q.settings.startTime ? dayjs(q.settings.startTime) : null,
      endTime: q.settings.endTime ? dayjs(q.settings.endTime) : null,
      points: q.settings.points || null
    }
  })
  return sections
    .sort((s1, s2) => s1.orderNumber - s2.orderNumber)
    .map(formatSettings)
    .map(
      (s): Question => ({
        ...s,
        children: questions
          .filter(q => s.childIds.includes(q.uuid))
          .map(formatSettings)
          .sort((q1, q2) => q1.orderNumber - q2.orderNumber)
      })
    )
}

export const formatEpisode = (episode: RawEpisode): Episode => {
  const { revealTime, endTime, startTime } = episode.classification
  return {
    ...episode,
    questions: formatQuestions(episode.questions),
    startTime: startTime ? dayjs(startTime) : null,
    revealTime: revealTime ? dayjs(revealTime) : null,
    endTime: endTime ? dayjs(endTime) : null
  }
}

const formatSerieEpisode = (episode: RawSerieEpisode): SerieEpisode => {
  return {
    ...episode,
    startTime: episode.startTime ? dayjs(episode.startTime) : null,
    revealTime: episode.revealTime ? dayjs(episode.revealTime) : null,
    endTime: episode.endTime ? dayjs(episode.endTime) : null
  }
}

export const formatSeries = (series: RawSeries): Series => ({
  ...series,
  exams: series.exams.map(formatSerieEpisode)
})
