.button {
	all: unset;
  cursor: pointer;
  padding: 8px 16px;
  box-shadow: var(--shadow-sm);
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  border-radius: var(--border-radius-md);
  color: var(--color-black-1);
  font-weight: var(--font-weight--semibold);
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  justify-items: center;
}

.button:disabled {
  cursor: not-allowed;
  background-image: none;
  background-color: rgba(204, 204, 204, 0.5);
  color: var(--color-black-1);
  box-shadow: none;
}

.button:focus-visible {
  outline: 3px solid var(--color-active-3);
  outline-offset: 2px;
}

.left {
  display: flex;
  justify-self: start;
}

.right {
  display: flex;
  justify-self: end;
}

.shinyGold {
  background-image: linear-gradient(to bottom right, var(--color-beige-3), var(--color-beige-2));
  box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.4);
}

.dimGold {
  background-image: linear-gradient(to right, var(--color-beige-3), var(--color-beige-2));
}

.purple {
  color: var(--text-primary);
  background-image: linear-gradient(to bottom right, var(--color-active-3), var(--color-active-2), var(--color-active-1));
}

.transparent {
  border: 3px solid var(--color-beige-3);
  box-shadow: 0 0 3px 1px var(--color-beige-2);
  color: var(--text-primary);
}

.white {
  color: var(--color-black-1);
  background-color: var(--text-primary);
}

.white:disabled {
  color: var(--color-black-4);
}

.text {
  color: var(--text-primary);
  box-shadow: none;
  text-decoration: underline;
}
