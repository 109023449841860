import * as vuorovaikutusApi from './vuorovaikutus-api'

let loggedIn = false

export const vuorovaikutusLoginOnce = (): void => {
  if (loggedIn) {
    return
  }

  try {
    vuorovaikutusApi.vuorovaikutusLogin().then(() => {
      loggedIn = true
    })
  } catch (e) {
    // TODO: handle errors
    if (window.getEnv() === 'dev') {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }
}
