import React from 'react'
import type { TunnusState } from './yleTunnus'

export const defaultState: TunnusState = {
  user: null,
  loggedIn: null
}

const YleTunnusContext = React.createContext<TunnusState>(defaultState)
YleTunnusContext.displayName = 'YleTunnus'
export default YleTunnusContext
