.container {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1;
  padding: 0 12px;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 320px) {
  .container {
    padding: 0;
  }
}

.container > button {
  color: var(--color-black-1);
}

.signBoardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2px;
  background-image: linear-gradient(to bottom, var(--color-beige-3), var(--color-beige-2), var(--color-beige-1));
  border-radius: var(--border-radius-sm);
}

.signBoard {
  align-self: center;
}

.signBoardContentTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
}

.signBoardContentBottom {
  color: var(--text-primary);
  padding: 12px;
}

.header1 {
  font-size: 18px;
  font-weight: var(--font-weight--bold);
  margin: 2px 0;
}

.header2 {
  font-size: 14px;
  font-weight: var(--font-weight--bold);
  margin: 2px 0;
}

.header3 {
  font-size: 14px;
  margin: 0;
  font-weight: var(--font-weight--semibold);
  text-transform: uppercase;
}

.separator {
  height: 1px;
  background-color: var(--text-primary);
  align-self: stretch;
}
