import clsx from 'clsx'
import React, { FC, ReactNode } from 'react'
import * as styles from './background.module.css'

type BackgroundProps = {
  children: React.ReactNode
  overlay?: 'purple' | 'white'
  hasBlur?: boolean
  hasGoldenEdge?: boolean
}

type OverlayProps = {
  children?: ReactNode
  isEnabled?: boolean
  className: string
}

type StyledOverlayProps = Omit<OverlayProps, 'className'>

const Overlay: FC<OverlayProps> = ({ children, isEnabled, className }) =>
  isEnabled ? <div className={className}>{children}</div> : <>{children}</>

const GoldenEdge: FC<StyledOverlayProps> = props => (
  <Overlay {...props} className={styles.goldenEdge} />
)

const Padding: FC<StyledOverlayProps> = props => (
  <Overlay {...props} isEnabled={true} className={styles.padding} />
)

const Background: FC<BackgroundProps> = ({ children, overlay, hasGoldenEdge, hasBlur }) => {
  return (
    <div className={styles.background}>
      <Overlay
        isEnabled={overlay !== undefined || hasBlur}
        className={clsx({
          [styles.purple]: overlay === 'purple',
          [styles.white]: overlay === 'white',
          [styles.blur]: hasBlur
        })}
      >
        <GoldenEdge isEnabled={hasGoldenEdge} />
        <Padding>{children}</Padding>
      </Overlay>
    </div>
  )
}

export default Background
